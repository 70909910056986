export default [
    {
        to: { name: "admin.index" },
        icon: "mdi-home",
        text: "Dashboard"
    },
    /*  {
        to: {name: 'admin.price-plans.index'},
        icon: 'mdi-application-cog',
        text: 'Price Plans',
    },*/
    {
        to: { name: "admin.users.index" },
        icon: "mdi-account",
        text: "Users"
    },
    {
        to: { name: "admin.projects.index" },
        icon: "mdi-domain",
        text: "Projects"
    },
    {
        to: { name: "admin.apartments.index" },
        icon: "mdi-floor-plan",
        text: "Apartments"
    },
    {
        to: { name: "admin.developers.index" },
        icon: "mdi-account-cog",
        text: "Developers"
    },
    {
        to: { name: "admin.banks.index" },
        icon: "mdi-bank",
        text: "Banks"
    },
    {
        to: { name: "admin.cities.index" },
        icon: "mdi-city-variant-outline",
        text: "Cities"
    },
    {
        to: { name: "admin.leads.index" },
        icon: "mdi-account-star",
        text: "Leads"
    },
    {
        to: { name: "admin.mortgages.index" },
        icon: "mdi-cash-multiple",
        text: "Mortgages"
    },
    {
        to: { name: "admin.ads.index" },
        icon: "mdi-seal",
        text: "Ads"
    },
    {
        to: { name: "admin.blogs.index" },
        icon: "mdi-file-document",
        text: "Blogs"
    },
    {
        to: { name: "admin.articles.index" },
        icon: "mdi-file-document",
        text: "Articles"
    },
    {
        to: { name: "admin.pages.index" },
        icon: "mdi-file-document",
        text: "Pages"
    },
    {
        to: { name: "admin.comments.index" },
        icon: "mdi-seal",
        text: "Comments"
    },
    {
        to: { name: "admin.tags.index" },
        icon: "mdi-seal",
        text: "Tags"
    },
    {
        to: { name: "admin.reviews.index" },
        icon: "mdi-seal",
        text: "Reviews"
    },
    {
        to: { name: "admin.documents.index" },
        icon: "mdi-file-document",
        text: "Documents"
    }
];
