<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn color="grey" :to="{name: 'admin.developers.create'}">Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="developers"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.name="{ item }">
                <v-row class="flex-column">
                    <v-col class="cursor-pointer text--blue" @click="handleClickName(item)">
                        {{ item.name_hy }}
                    </v-col>
                    <v-col class="cursor-pointer text--blue" @click="handleClickName(item)">
                        {{ item.name_ru }}
                    </v-col>
                    <v-col class="cursor-pointer text--blue" @click="handleClickName(item)">
                        {{ item.name_en }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.description="{ item }">
                <v-row class="flex-column">
                    <v-col>
                        {{ item.description_hy }}
                    </v-col>
                    <v-col>
                        {{ item.description_ru }}
                    </v-col>
                    <v-col>
                        {{ item.description_en }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }} <!--TODO add day.js and default format -->
            </template>
            <template v-slot:item.actions="{ item }">
                <developer-delete-dialog @deleted="handleRemoveDeveloper" :developer="item" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import Developer from '../../../models/Developer';
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import DeveloperDeleteDialog from "../../../components/dialogs/DeveloperDeleteDialog";

export default {
    name: "developers-index",
    components: {AdminDashboardLayout, DeveloperDeleteDialog},
    data: function () {
        return {
            loading: false,
            developers: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name', value: 'name'},
                {text: 'Description', value: 'description'},
                {text: 'Logo url', value: 'logo_url'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    methods: {
        handleClickName(developer) {
            this.$router.push({name: 'admin.developers.show', params: {id: developer.id}})
        },
        handleRemoveDeveloper(id) {
            this.developers = this.$_.filter(this.developers, developer => developer.id !== id);
        }
    },
    async mounted() {
        this.loading = true
        this.developers = await Developer.get()
        this.loading = false
    },
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.text--blue {
    color: #0d47a1;
}

.text--blue:hover {
    text-decoration: underline;
}
</style>
